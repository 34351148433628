// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container'];

  open(event){
    event.preventDefault();

    this.containerTarget.classList.add('enabled');
    $('.enabled input[disabled], .enabled select[disabled]').removeAttr('disabled');

    return false;
  }

  close(event){
    event.preventDefault();

    $('.enabled input, .enabled select').attr('disabled', 'disabled');
    this.containerTarget.classList.remove('enabled');

    return false;
  }
}
