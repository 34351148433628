// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container'];

  append(event) {
    const [data, status, xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response;

  }
}
